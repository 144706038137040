import { createContext } from "react";
import _ from "lodash/fp";

import { InitialCartState, ICartProps } from "types/state";
import { ADD_CART_ITEM, REMOVE_CART_ITEM, UPDATE_QUANTITY, RESET} from "constants/cart";
import { updateQuantity } from "utils/cart";

export const initialCartState: InitialCartState = { items: [] };

export const reducer = (state: InitialCartState = initialCartState, action: any): InitialCartState => {
  switch (action.type) {
    case ADD_CART_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload.item]
      };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        items: _.filter(item => item.id !== action.payload.item.id, state.items)
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        items: updateQuantity(action.payload.id, action.payload.qty)(state.items)
      };
    case RESET:
      return initialCartState
    default:
      return state;
  }
};

export const CartContext = createContext({} as ICartProps);
