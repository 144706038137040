import React, { useReducer } from "react"
import { SceneView } from "@react-navigation/core"
import Navbar from "../components/navbars/Navbar"
import Footer from "../components/footers/Footer"

import { reducer, initialCartState, CartContext } from "store/cart"

type Props = {
  descriptors: any // FIXME:
  navigation: any // FIXME:
};

export default (props: Props) => {
  const [store, dispatch] = useReducer(reducer, initialCartState)
  const { descriptors, navigation } = props
  const activeKey = navigation.state.routes[navigation.state.index].key
  const descriptor = descriptors[activeKey]
  return (
    <CartContext.Provider value={{ store, dispatch }}>
      <div id="main-body">
        <Navbar />
        <div className="flex min-h-screen w-full">
          <SceneView
            component={descriptor.getComponent()}
            navigation={descriptor.navigation}
          />
        </div>
        <Footer />
      </div>
    </CartContext.Provider>
  )
}