import React from 'react'

import background from 'assets/images/logo-background.png'
import { withOptions } from '../utils/navigation'

const AboutUs = () => {
  return (
    <div className="w-full min-h-screen px-3 md:px-0" style={{ background: `url(${background})` }}>
      <div className="flex w-full mx-auto justify-center pt-24">
        <span className="text-5xl text-center">
          WELCOME TO SOCAL <br /> SHIBA ADVENTURES
        </span>
      </div>
      <div className="max-w-3xl mx-auto text-center pt-10 text-lg mb-24">
        <div className="pb-10">We are a social Shiba meet up group based out of Southern California that’s looking to connect dog lovers together with similar interests in outdoor activities.</div>
        <div className="pb-10">Our mission is to motivate the adventuring spirit between owners and dogs to get out and explore more.</div>
        <div className="pb-10">This is a group open to everyone that is either in or visiting Southern California and is not limited to Shibas. We love all dogs!</div>
        <div className="pb-10">We will actively share the adventures through our stories and posts. As part of our meet up group, we welcome all attendees to hashtag.</div>
        <div className="pb-10">#SocalShibaAdventures</div>
        <div className="pb-10">For a chance at being featured on our page, tag us on instagram.</div>
        <div className="pb-10">@socalshibaadventures</div>
      </div>
    </div>
  )
}

export default withOptions({ path: 'about-us', title: 'About Us' })(AboutUs)