import React, { Fragment, useState, useContext } from "react";
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { NavigationActions } from '@react-navigation/core';
import _ from 'lodash/fp'

import poweredByStripe from "assets/images/powered_by_stripe.png";
import SelectInput from './SelectInput';
import TextInput from './TextInput';
import { Item } from "types/state";
import { states } from 'utils/forms';
import { CartContext } from "store/cart";
import { RESET } from "constants/cart";

type Props = {
  state: any
  onChange: any
  stripe?: any
  items: Array<Item>
  navigation: any
  total: string
}

const stripeStyle = {
  base: {fontWeight: 'normal', fontSize: '16px', color: '#4a5568', lineHeight: '1.25'}
}

type ErrorState = Array<string>

const initialErrorState: ErrorState = ['']


const CardError: React.FC<any> = ({ message }) => (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-3" role="alert">
    <strong className="font-bold">Card Error: </strong>
    <span className="block sm:inline">{message}</span>
  </div>
)

const CheckoutForm: React.FC<Props> = (props) => {
  const { dispatch } = useContext(CartContext);
  const [errors, setErrors] = useState(initialErrorState)
  const [cardError, setCardError] = useState({message: ''})
  const {state, onChange, navigation} = props;
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const requiredFields = ['email', 'name', 'line1', 'city', 'zip', 'address_zip'];
    const validatedFields = _.filter((requiredField: string) => state[requiredField].length === 0)(requiredFields)
    if (validatedFields.length > 0) {
      setErrors(validatedFields);
      return;
    }

    const tokenResponse = await props.stripe.createToken({name: state.name, email: state.email, address_zip: state.address_zip });
    if (tokenResponse.error) {
      setErrors([tokenResponse.error.code]);
      return;
    }
    const response = await fetch('https://api.socalshibaadventures.com/charge',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...state, token: tokenResponse.token.id, cart: props.items })
    }).then(response => response.json());
    if (response.error) {
      setCardError(response.error);
      return;
    }
    dispatch({ type: RESET });
    return navigation.dispatch(NavigationActions.navigate({ routeName: 'Receipt', params: { receipt: response } }));
  }

  const renderError = (cardError.message) ? <CardError message={cardError.message} /> : null;

  const formatZip = (value: string) => value.replace(/[^\dA-Z]/g, '').replace(/^.{5,6}$/g, value.slice(0, 5));
  return (
    <Fragment>
      <div className="text-center">
        <span className="text-5xl uppercase">Checkout</span>
        <img src={poweredByStripe} className="mx-auto py-3 md:px-6" alt="" />
      </div>
      <div className="text-gray-700 font-bold py-6 mx-2 md:mx-0">
        <h1>Personal / Shipping Information:</h1>
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="Instagram (Optional)" fieldName="instagram" value={state.instagram} onChange={ (event: any) => onChange(event, 'instagram') } />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="Email for receipt" fieldName="email" value={state.email} onChange={ (event: any) => onChange(event, 'email') } error={errors.indexOf('email') !== -1} />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="Full Name" fieldName="name" value={state.name} onChange={ (event: any) => onChange(event, 'name') } error={errors.indexOf('name') !== -1} />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="Street Address" fieldName="line1" value={state.line1} onChange={ (event: any) => onChange(event, 'line1') } error={errors.indexOf('line1') !== -1} />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="Apt #" fieldName="line2" value={state.line2} onChange={ (event: any) => onChange(event, 'line2') } />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <TextInput placeholder="City" fieldName="city" value={state.city} onChange={ (event: any) => onChange(event, 'city') } error={errors.indexOf('city') !== -1} className="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0"  />
        <SelectInput options={states} value={state.state} onChange={ (event: any) => onChange(event, 'state') } />
        <TextInput placeholder="Zip Code" fieldName="zip" value={state.zip} onChange={ (event: any) => onChange(event, 'zip', formatZip) } error={errors.indexOf('zip') !== -1} className="w-full md:w-1/3 mb-6 md:mb-0"  />
      </div>
      <div className="text-gray-700 font-bold py-6 mx-2 md:mx-0">
        <h1>Payment Information:</h1>
        { renderError }
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <CardNumberElement className={`appearance-none block w-full border-b border-gray-500 py-3 mb-3 focus:outline-none focus:bg-white ${errors.indexOf('incomplete_number') !== -1 ? 'border-red-500 text-red-500' : ''}`} style={stripeStyle} />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <div className="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0">
          <CardExpiryElement className={`appearance-none block border-b border-gray-500 py-3 focus:outline-none focus:bg-white ${errors.indexOf('incomplete_expiry') !== -1 ? 'border-red-500 text-red-500' : ''}`} style={stripeStyle} />
        </div>
        <div className="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0">
          <CardCVCElement className={`appearance-none block border-b border-gray-500 py-3 focus:outline-none focus:bg-white ${errors.indexOf('incomplete_cvc') !== -1 ? 'border-red-500 text-red-500' : ''}`} style={stripeStyle} />
        </div>
        <TextInput placeholder="ZIP" fieldName="address_zip" value={state.address_zip} onChange={ (event: any) => onChange(event, 'address_zip', formatZip) } error={errors.indexOf('address_zip') !== -1} className="w-full md:w-1/3 mb-6 md:mb-0" />
      </div>
      <div className="flex flex-wrap mx-2 md:mx-0">
        <button className="w-full bg-main hover:bg-main-alt text-main-color hover:text-main-color-alt py-2 px-12 rounded mt-3 md:mt-6" onClick={handleSubmit}>
          Pay ${props.total}
        </button>
      </div>
    </Fragment>
  )
}

export default injectStripe(CheckoutForm);