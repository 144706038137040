import React from "react";

import { Item } from "types/state";
import AddToCartButton from "../buttons/AddToCartButton";

type Props = {
  item: Item;
};

const Product: React.FC<Props> = ({ item }) => {
  return (
    <div className="w-full md:w-1/2 xl:w-1/2 pt-10 px-10" style={{ maxWidth: "354px" }}>
      <div className="flex-column items-center justify-center">
        <div className="flex-column">
          <img src={item.image} alt="" />
        </div>
        <div className="py-2">{item.name}</div>
        <div className="text-gray-600">${(item.price / 100).toFixed(2)}</div>
        <div className="flex pt-2">
          <AddToCartButton item={item} />
        </div>
      </div>
    </div>
  );
};

export default Product;
