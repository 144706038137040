import React from "react";
import { withNavigation, NavigationActions } from "@react-navigation/core";

type Props = {
  routeName: string;
  navigation: any; // FIXME:
  className: string;
  href: string;
  onClick: any; // FIXME:
};

const Link: React.FC<Props> = props => {
  const _navigate = (event: any) => {
    // props.onClick();
    const { navigation, routeName } = props;
    event.preventDefault();
    navigation.dispatch(NavigationActions.navigate({ routeName }));
  };

  const { children, className, href } = props;
  return (
    <a href={href} onClick={_navigate} className={`${className} cursor-pointer`}>
      {children}
    </a>
  );
};

export default withNavigation(Link);
