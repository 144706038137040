import React, { useContext } from "react";

import { Item } from "types/state";
import { CartContext } from "store/cart";
import { ADD_CART_ITEM } from "constants/cart";

type Props = {
  item: Item;
};

const AddToCartButton: React.FC<Props> = ({ item }) => {
  const { store, dispatch } = useContext(CartContext);
  const itemInCart = store.items.indexOf(item) !== -1;
  const addToCart = (item: Item) => (dispatch: any) => dispatch({ type: ADD_CART_ITEM, payload: { item } });
  const buttonText = itemInCart ? (
    <span>
      <i className="fas fa-check" /> In Cart
    </span>
  ) : (
    "Add to Cart"
  );
  return (
    <button
      className="w-full bg-main text-main-color rounded px-6 py-2"
      disabled={itemInCart}
      onClick={() => addToCart(item)(dispatch)}
    >
      {buttonText}
    </button>
  );
};

export default AddToCartButton;
