import React, { Fragment, useContext } from "react";

import { Item } from "types/state";
import LineBreak from "components/misc/LineBreak";
import RangeOptions from 'components/misc/RangeOptions'
import { CartContext } from "store/cart";
import { UPDATE_QUANTITY } from "constants/cart";
import { removeFromCart } from "utils/cart";

type Props = {
  item: Item;
};

const CartProduct: React.FC<Props> = ({ item }) => {
  const { dispatch } = useContext(CartContext);
  const removeItem = removeFromCart(dispatch);
  const handleOnChange = (event: React.ChangeEvent<any>) => {
    dispatch({ type: UPDATE_QUANTITY, payload: { id: item.id, qty: event.target.value } });
  };
  const total = ((item.price * item.quantity) / 100).toFixed(2);
  return (
    <Fragment>
      <div className="container block w-full mx-auto py-6 mt-2 md:hidden">
        <div className="h-20 w-full text-center">
            <span className="w-full text-left text-lg md:text-md">{item.name}</span>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex h-20 justify-center align-items">
            <button className="text-2xl hover:text-main" onClick={() => removeItem(item)}>
              X
            </button>
          </div>
          <div className="flex items-center">
            <img src={item.image} alt="" className="w-20 ml-10" />
          </div>
          <div className="flex h-20 w-1/3 justify-end items-center">
            <span className="text-md">${total}</span>
          </div>
        </div>
        <div className="container block w-full mx-auto mt-2">
          <div className="flex h-20 w-full justify-center items-center">
            <label className="mr-4">Qty.</label>
            <div className="relative w-full">
              <select
                value={item.quantity}
                onChange={event => handleOnChange(event)}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <RangeOptions item={item} />
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl container mx-auto py-6 mt-6 hidden md:flex">
        <div className="flex h-20 justify-center align-items">
          <button className="text-2xl hover:text-main" onClick={() => removeItem(item)}>
            X
          </button>
        </div>
        <div className="flex items-center">
          <img src={item.image} alt="" className="w-20 ml-10" />
        </div>
        <div className="flex h-20 w-1/3 ml-24 justify-center items-center">
          <span className="w-full text-left text-md">{item.name}</span>
        </div>
        <div className="flex h-20 w-1/3 justify-center items-center">
          <label className="mr-4">Qty.</label>
          <div className="relative">
            <select
              value={item.quantity}
              onChange={event => handleOnChange(event)}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <RangeOptions item={item} />
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex h-20 w-1/3 justify-end items-center">
          <span className="text-md">${total}</span>
        </div>
      </div>
      <LineBreak />
    </Fragment>
  );
};

export default CartProduct;
