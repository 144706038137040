import React from 'react'

import background from 'assets/images/doggy-background.png'
import { withOptions } from 'utils/navigation';

const Landing = () => {
  return (
    <div className="flex w-full overflow-hidden">
      <div className="flex w-full">
        <div className="min-w-full min-h-screen" style={{ background: `url(${background})`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
      </div>
    </div>
  )
}

export default withOptions({ path: '', title: 'Home' })(Landing)