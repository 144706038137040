import React from "react";
import { NavigationActions } from "@react-navigation/core";
import _ from 'lodash'

import { withOptions } from "utils/navigation";
import ImageBackground from "components/misc/ImageBackground";

type Props = {
  navigation: any
}

const Receipt: React.FC<Props> = ({ navigation }) => {
  // Guard clause to navigate to shop if cart is empty and return null for render
  if (_.isEmpty(navigation.state.params)) {
    navigation.dispatch(NavigationActions.navigate({ routeName: "Shop" }));
    return null;
  }
  return (
    <ImageBackground className="flex justify-center align-center items-center text-center">
      <div className="">
        <span className="text-5xl">Thank you for shopping with us!</span>
        <div className="mt-3 md:mt-6">
          <span className="text-2xl text-gray-700">Please check your email for your receipt.</span>
        </div>
        <div className="mt-3 md:mt-6">
          <span className="text-2xl text-gray-700">Please allow 7 - 10 business days for shipping.</span>
        </div>
        <div className="mt-3 md:mt-6">
          <span className="text-2xl text-gray-700">Order # {navigation.state.params.receipt.id}</span>
        </div>
      </div>
    </ImageBackground>
  );
};

export default withOptions({ path: "receipt", title: "Receipt" })(Receipt);