import React, { Fragment } from "react";
import _ from "lodash/fp";

import Product from "./Product";
import ProductWide from "./ProductWide";
import { Item } from "types/state";

type Props = {
  items: Item[];
};

const ProductList: React.FC<Props> = ({ items }) => {
  const determineProductComponent = (item: Item) =>
    item.metadata.wide ? <ProductWide key={item.id} item={item} /> : <Product key={item.id} item={item} />;
  const renderProducts = () => _.map(determineProductComponent)(items);
  return <Fragment>{renderProducts()}</Fragment>;
};

export default ProductList;
