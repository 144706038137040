import React from 'react'

import Link from '../links/Link'

const EmptyCart = () => (
  <div className="max-w-5xl mx-auto text-center pt-12">
    <div className="text-3xl pb-12">Your cart is currently empty</div>
    <Link
      routeName="Shop"
      href="shop"
      className="bg-main hover:bg-main-alt text-main-color hover:text-main-color-alt py-2 px-4 rounded"
      >
      Continue Shopping
    </Link>
  </div>
)

export default EmptyCart