import React from 'react'

const Footer = () => {

  const _getYear = () => (new Date()).getFullYear()

  return (
    <footer className="fixed w-full text-xs md:text-lg text-center text-main-color py-4 bottom-0 bg-main z-50">
      {_getYear()} © Copyright SocalShibaAdventures.com. All Rights Reserved.
        <div className="fixed right-0 bottom-0 pb-3 lg:pr-24 invisible lg:visible">
        <a href="https://www.instagram.com/socalshibaadventures" target="_blank" rel="noopener noreferrer" className="flex text-main-color hover:text-main-color-alt justify-center items-center">
          Follow us <i className="fab fa-instagram text-4xl ml-4"></i>
        </a>
      </div>
    </footer>
  )
}

export default Footer