import React, { createRef, useContext } from 'react'

import logo from 'assets/images/color-logo-sm.png'
import Link from '../links/Link'
import { CartContext } from 'store/cart'

const Navbar = () => {
  const { store } = useContext(CartContext)
  const cartCount = store.items.length
  const menuRef = createRef<HTMLDivElement>()
  const cartCountClass = (cartCount > 0) ? '' : 'invisible'
  const _toggleMenu = () => menuRef.current!.classList.toggle('hidden')
  return (
    <nav className={`fixed w-full top-0 flex items-center justify-between flex-wrap py-2 px-4 lg:px-24 bg-main z-50`}>
      <div className="flex items-center flex-shrink-0 text-black mr-6">
        <Link routeName="Landing" href="/">
          <img src={logo} className="logo" alt="" />
        </Link>
      </div>
      <div className="block lg:hidden">
        <button onClick={_toggleMenu} className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-black hover:border-black">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
        </button>
      </div>
      <div ref={menuRef} className="w-full xs:block xs:flex-grow text-center lg:flex lg:items-center lg:w-auto float-right hidden">
        <div className="text-sm lg:flex-grow">
          <Link routeName="Shop" className="block mt-4 lg:inline-block lg:mt-0 text-main-color hover:text-main-color-alt md:mr-12" href="shop" onClick={_toggleMenu}>
            Shop
          </Link>
          <Link routeName="Cart" className="block mt-4 text-lg lg:inline-block lg:mt-0 text-main-color hover:text-main-color-alt md:mr-12" href="cart" onClick={_toggleMenu}>
            <i className="fas fa-shopping-cart"></i>
            <span className={`absolute md:flex -mt-1 md:-mt-8 -ml-2 md:ml-3 w-4 h-4 justify-center items-center text-xs font-medium text-red-lightest bg-main border border-main-color rounded-full ${cartCountClass}`}>{ cartCount }</span>
          </Link>
          {/* <Link routeName="Events" className="block mt-4 lg:inline-block lg:mt-0 text-main-color hover:text-main-color-alt md:mr-12" href="events" onClick={_toggleMenu}>
            Events
          </Link> */}
          <Link routeName="AboutUs" className="block mt-4 lg:inline-block lg:mt-0 text-main-color hover:text-main-color-alt" href="about-us" onClick={_toggleMenu}>
            About Us
          </Link>
          <a href="https://www.instagram.com/socalshibaadventures" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center my-4 lg:inline-block lg:mt-0 text-white hover:text-black lg:hidden">
            <span className="mr-2">Follow us</span> <i className="fab fa-instagram text-3xl"></i>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar