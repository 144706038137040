import React, { useContext, useState } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";

import ImageBackground from "components/misc/ImageBackground";
import { withOptions } from "utils/navigation";
import { CartContext } from "store/cart";
import { NavigationActions } from "@react-navigation/core";
import { getSubTotal } from "utils/cart";
import CheckoutForm from "components/form/CheckoutForm";

type Props = {
  navigation: any;
};

const initialState = {
  instagram: "",
  email: "",
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "AL",
  zip: "",
  address_zip: ""
}

const Checkout: React.FC<Props> = props => {
  const [state, setState] = useState(initialState);
  const { store } = useContext(CartContext);
  // Guard clause to navigate to shop if cart is empty and return null for render
  if (store.items.length === 0) {
    props.navigation.dispatch(NavigationActions.navigate({ routeName: "Shop" }));
    return null;
  }
  const grandTotal = getSubTotal(store.items).toFixed(2);
  const onChange = (event: any, field: string, func: (event: any) => string | number | undefined | null) => {
    setState({ ...state, [field]: func ? func(event.target.value) : event.target.value });
  };
  return (
    <ImageBackground>
      <StripeProvider apiKey="pk_live_cGqxDGBXJ9it9rTA6Mlvx9qN00o5sImw0T">
        <Elements>
          <form className="w-full mx-auto max-w-lg pt-24 mb-24">
            <CheckoutForm state={state} total={grandTotal} onChange={onChange} items={store.items} navigation={props.navigation}/>
          </form>
        </Elements>
      </StripeProvider>
    </ImageBackground>
  );
};

export default withOptions({ path: "checkout", title: "Checkout" })(Checkout);
