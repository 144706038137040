import React, { useReducer } from 'react'

import background from 'assets/images/logo-background.png'
import adventureReady from 'assets/images/adventure-ready.png'

import ProductList from 'components/listings/ProductList'
import { withOptions } from 'utils/navigation'
import { reducer, initialProductsState, ProductsContext } from 'store/products'
import { NavigationActions } from '@react-navigation/core';

const Shop: React.FC<any> = (props) => {
  const [store, dispatch] = useReducer(reducer, initialProductsState)
  const _navigateToCart = () => {
    props.navigation.dispatch(NavigationActions.navigate({ routeName: 'Cart' }));
  }
  return (
    <ProductsContext.Provider value={{ store, dispatch }}>
      <div className="w-full min-h-screen mb-24" style={{ background: `url(${background})` }}>
        <div className="flex w-full mx-auto justify-center pt-24">
          <img src={adventureReady} alt="" />
        </div>
        <div className="flex flex-wrap max-w-4xl mx-auto justify-center">
          <ProductList items={store.items}/>
        </div>
        <div className="flex max-w-4xl mx-auto justify-center">
          <div className="w-full pt-10 px-10">
            <button className="w-full bg-main text-main-color rounded px-6 py-2 md:hidden" onClick={_navigateToCart}>
              <i className="fas fa-shopping-cart"></i> Continue to Cart
            </button>
          </div>
        </div>
      </div>
    </ProductsContext.Provider>
  )
}

export default withOptions({ path: 'shop', title: 'Shop' })(Shop)
