import React from "react";

type FormProps = {
  value: string | number | undefined;
  onChange: any;
  placeholder: string;
  fieldName: string;
  className?: string;
  error?: boolean;
};

const TextInput: React.FC<FormProps> = ({ value, onChange, placeholder, fieldName, className, error }) => {
  /*
  Alt size: w-full md:w-1/3 md:pr-3 mb-6 md:mb-0
  Error border: border-red-500
  Error text: text-red-500
  */
  const errorClasses = error ? 'border-red-500 text-red-500' : '';
  // const errorMessage = error ? <p className="text-red-500 text-xs italic mb-3">Please fill out this field.</p> : null;
  return (
    <div className={className ? className : "w-full mb-6 md:mb-0"}>
      <input
        className={`appearance-none block w-full text-gray-700 border-b border-gray-500 py-3 mb-3 leading-tight focus:outline-none focus:bg-white ${errorClasses}`}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete={`new-${fieldName}`}
      />
      {/* <p className="text-red-500 text-xs italic mb-3">Please fill out this field.</p> */}
      {/* <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
    </div>
  );
};

export default TextInput;
