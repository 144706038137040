import React, { Fragment } from "react";
import _ from "lodash/fp";

import { Item } from "types/state";

type Props = {
  item: Item;
};

const RangeOptions: React.FC<Props> = ({ item }) => {
  const option = (item: Item) => (num: number) => (
    <option key={`qty-${item.id}-${num}`} value={num}>
      {num}
    </option>
  );
  return (
    <Fragment>
      { _.map(option(item))(_.range(1, 11)) }
    </Fragment>
  );
}

export default RangeOptions;
