import { createContext } from 'react'

import { InitialProductsState, IProductsProps } from 'types/state'
// import { FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS } from 'constants/products';

export const initialProductsState: InitialProductsState = {
  loading: false,
  items: [
    {
      "id": "clr-ptch-2in",
      "name": "Color Patch 2x2 Inch",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "2",
        "color": "Multi",
        "unit": "Inch"
      },
      "created": 1561605956,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/color-patch-2x2.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": false
      },
      "package_dimensions": null,
      "price": 600,
      "product": "clr-ptch-2in",
      "updated": 1561605956,
      "quantity": 1
    },
    {
      "id": "clr-stkr-3in",
      "name": "Color Sticker 3x3 Inch",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "3",
        "color": "Multi",
        "unit": "Inch"
      },
      "created": 1561605878,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/color-sticker-3x3.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": false
      },
      "package_dimensions": null,
      "price": 400,
      "product": "clr-stkr-3in",
      "updated": 1561605878,
      "quantity": 1
    },
    {
      "id": "wht-dcl-3in",
      "name": "White Decal 3x3 Inch",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "3",
        "color": "White",
        "unit": "Inch"
      },
      "created": 1561605707,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/white-decal-3x3.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": false
      },
      "package_dimensions": null,
      "price": 400,
      "product": "wht-dcl-3in",
      "updated": 1561605707,
      "quantity": 1
    },
    {
      "id": "blk-dcl-3in",
      "name": "Black Decal 3x3 Inch",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "3",
        "color": "Black",
        "unit": "Inch"
      },
      "created": 1561525420,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/black-decal-3x3.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": false
      },
      "package_dimensions": null,
      "price": 400,
      "product": "blk-dcl-3in",
      "updated": 1561525420,
      "quantity": 1
    },
    {
      "id": "cmbo-pk-blk-wht-dcls",
      "name": "Combo Pack Black and White 3x3 Inch Decals",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "3",
        "color": "Multi",
        "unit": "Inch"
      },
      "created": 1561525420,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/cmbo-pk-blk-wht-dcls.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": true
      },
      "package_dimensions": null,
      "price": 600,
      "product": "cmbo-pk-blk-wht-dcls",
      "updated": 1561525420,
      "quantity": 1
    },
    {
      "id": "cmbo-4-pk",
      "name": "Combo Pack One of each Decal, Sticker and Patch",
      "object": "sku",
      "active": true,
      "attributes": {
        "size": "Multi",
        "color": "Multi",
        "unit": "Inch"
      },
      "created": 1561525420,
      "currency": "usd",
      "image": "https://socalshibaadventures.com/images/cmbo-4-pk.png",
      "inventory": {
        "quantity": 100,
        "type": "finite",
        "value": null
      },
      "livemode": false,
      "metadata": {
        "wide": true
      },
      "package_dimensions": null,
      "price": 1400,
      "product": "cmbo-4-pk",
      "updated": 1561525420,
      "quantity": 1
    }
  ]
}

export const reducer = (state: InitialProductsState = initialProductsState, action: any) => {
  switch(action.type) {
    // case FETCH_PRODUCT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case FETCH_PRODUCT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false
    //   }
    default:
      return state
  }
}

export const ProductsContext = createContext({} as IProductsProps)