import _ from 'lodash/fp';

import { Item } from 'types/state';
import { REMOVE_CART_ITEM } from 'constants/cart';

export const getSubTotal = (items: Item[], multiplier: number = 0.01) =>
  _.reduce((acc: number, item: Item) => acc + (item.price * item.quantity), 0)(items) * multiplier

export const updateQuantity = (id: string, quantity: number) => (items: Item[]) =>
  _.map((item: Item) => (item.id === id) ? { ...item, quantity } : item)(items)

export const removeFromCart = (dispatch: any) => (item: Item) =>
  dispatch({ type: REMOVE_CART_ITEM, payload: { item } })