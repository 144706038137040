import React from 'react'
import { withOptions } from 'utils/navigation';
import ImageBackground from 'components/misc/ImageBackground';

const PageMissing = () => (
  <ImageBackground className="flex justify-center align-center items-center">
    <div className="mr-6">
      <span className="text-6xl">(404)</span>
    </div>
    <div className="text-3xl">
      <span>Whoops! Heckin' hooman ate this page!</span>
    </div>
  </ImageBackground>
)

export default withOptions({ path: '*', title: '404 Not Found' })(PageMissing)