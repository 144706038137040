import { createNavigator, SwitchRouter } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

import Main from "templates/Main";
import Landing from "pages/Landing";
import Shop from "pages/Shop";
import Cart from "pages/Cart";
import Checkout from "pages/Checkout";
import Receipt from "pages/Receipt";
// import Events from 'pages/Events'
import AboutUs from "pages/AboutUs";
import PageMissing from "pages/PageMissing";

const AppNavigator: any = createNavigator(
  Main,
  SwitchRouter({
    Landing,
    Shop,
    Cart,
    Checkout,
    Receipt,
    // Events,
    AboutUs,
    // Handle all routes that do not exists
    "*": {
      screen: PageMissing
    }
  }),
  {}
);

export default createBrowserApp(AppNavigator);
