import React, { useContext } from 'react'

import CartList from 'components/listings/CartList'
import ImageBackground from 'components/misc/ImageBackground';
import LineBreak from 'components/misc/LineBreak'
import { withOptions } from 'utils/navigation'
import { CartContext } from 'store/cart';


const Cart: React.FC = () => {
  const { store } = useContext(CartContext)
  return (
    <ImageBackground>
      <div className="w-full mx-auto text-center pt-24">
        <span className="text-5xl uppercase">
          Shopping Cart
        </span>
      </div>
      <div className="w-full px-3 pt-24 mb-24">
        <LineBreak />
        <CartList items={store.items} />
      </div>
    </ImageBackground>
  )
}

export default withOptions({ path: 'cart', title: 'Cart' })(Cart)