import React from 'react'

type Props = {
  title: string
  value: number
}

const AmountRow: React.FC<Props> = (props) => (
  <div className="flex max-w-5xl container mx-auto pt-3">
    <div className="flex w-full justify-end text-2xl">
      <span className="text-right">{props.title}</span>
      <span className="w-32 text-right">${props.value.toFixed(2)}</span>
    </div>
  </div>
);

export default AmountRow;