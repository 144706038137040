import React from 'react';
import _ from 'lodash/fp';


type OptionProp = {
  value: any
  display: string
}

type Props = {
  value: string | number
  onChange: any
  options: Array<OptionProp>
}

const SelectInput: React.FC<Props> = ({ options, value, onChange }) => {
  const option = (option: OptionProp) => <option key={`select-${option.value}`} value={option.value}>{option.display}</option>
  const renderOptions = _.map(option)(options)
  return (
    <div className="w-full md:w-1/3 md:pr-3 mb-6 md:mb-0">
      {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">State</label> */}
      <div className="relative">
        <select
          className="block appearance-none w-full bg-transparent text-gray-700 border-b border-gray-500 rounded-none py-3 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-state"
          value={value}
          onChange={onChange}
        >
          { renderOptions }
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default SelectInput;