import React, { Component } from 'react'

type Options = {
  path: String,
  title: String,
  routeName?: String|null,
}

export const withOptions = <T extends Options>({ path, title, routeName = null }: T) => (WrappedComponent: any) => { // FIXME:
  const navigationOptions = {
    routeName: (routeName) ? routeName : title,
    title: `SocalShibaAdventures - ${title}`
  }
  return class extends Component {
    static path = path
    static navigationOptions = navigationOptions
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}