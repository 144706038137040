import React, { Fragment } from "react";
import _ from "lodash/fp";

import { Item } from "types/state";
import CartProduct from "./CartProduct";
import Link from "components/links/Link";
import AmountRow from "components/misc/AmountRow";
import { getSubTotal } from "utils/cart";

type Props = {
  items: Item[];
};

const FilledCart: React.FC<Props> = ({ items }) => {
  const product = (item: Item) => <CartProduct key={`cart-product-${item.id}`} item={item} />;
  const renderCartProducts = _.map(product)(items);
  const subTotal = getSubTotal(items);
  const grandTotal = subTotal;
  return (
    <Fragment>
      {renderCartProducts}
      <AmountRow title="Subtotal:" value={subTotal} />
      <AmountRow title="Shipping:" value={0} />
      <AmountRow title="Taxes:" value={0} />
      <AmountRow title="Grand Total:" value={grandTotal} />
      <div className="flex max-w-5xl container mx-auto pt-6">
        <div className="flex w-full justify-end">
          <Link
            routeName="Shop"
            href="shop"
            className="bg-main hover:bg-main-alt text-main-color hover:text-main-color-alt py-2 px-12 rounded mr-1 md:mr-12"
          >
            Continue Shopping
          </Link>
          <Link
            routeName="Checkout"
            href="checkout"
            className="bg-main hover:bg-main-alt text-main-color hover:text-main-color-alt py-2 px-12 rounded ml-1 md:mr-0"
          >
            Checkout
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default FilledCart;
