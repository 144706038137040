import React from 'react'

import background from 'assets/images/logo-background.png'


type Props = {
  className?: string
}

const ImageBackground: React.FC<Props> = ({ children, className }) => (
  <div className={`w-full min-h-screen ${className}`} style={{ background: `url(${background})` }}>
    { children }
  </div>
)

export default ImageBackground