import React from "react";

import { Item } from "types/state";
import FilledCart from "./FilledCart";
import EmptyCart from "./EmptyCart";

type Props = {
  items: Array<Item>;
};

export default (props: Props) => (props.items.length > 0) ? <FilledCart items={props.items} /> : <EmptyCart />